import React from "react";
import "./subnav.css";
import MyButton from "../MyButton";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

const SubNav = ({ currentPage, currentCity, onclick, cats, onCats, selectedCat }) => {
  return (
    <div
      className="mb-5 text-center"
      style={{ position: "fixed", zIndex: 60, width: "100%",backgroundColor:"whitesmoke" }}
    >
      <div
        className=" text-center"
        style={{
          overflow: "auto",
          borderBottomColor: "#AEAEAE",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          padding: 15,
          display: "flex",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: 20 }}
          onClick={() => {
            onclick();
          }}
        >
          <h2 className="easy-text" style={{ color: "#FF2E2E" }}>
            Delivering to
          </h2>
          <h2 style={{ margin: 0, fontSize: 16 }}>
            {currentCity ? currentCity : "No city selected"}{" "}
            <DownOutlined style={{ fontSize: 16 }} />
          </h2>
        </div>
        <Link to="/products">
          <MyButton
            text="All"
            type="primary"
            shape="round"
            style={{
              backgroundColor: currentPage === "products" ? "black" : "white",
              color: currentPage === "products" ? "white" : "black",
              fontWeight: "bold",
              borderColor: "black",
            }}
          />
        </Link>
        <Link to="/stores">
          <MyButton
            text="Stores"
            type="primary"
            shape="round"
            style={{
              backgroundColor: currentPage === "stores" ? "black" : "white",
              color: currentPage === "stores" ? "white" : "black",
              borderColor: "black",
            }}
          />
        </Link>
        {currentPage==="products"&& cats.map((i) => {
          return (
            <MyButton
              text={i.label}
              type="primary"
              onClick={() => onCats(i.value)}
              shape="round"
              style={{
                backgroundColor: selectedCat === i.value ? "black" : "white",
                color: selectedCat === i.value ? "white" : "black",
                borderColor: "black",
              }}
            />
          );
        })}
       
      </div>
    </div>
  );
};
export default SubNav;
