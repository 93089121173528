import React  from "react";
import Carousel from "react-gallery-carousel";
import '../../../views/app-views/products/layout.css';
import "./modal.css";
import { Row, Col, Button } from "antd";
import AddToCart from "../AddToCart";

const MyModal = ({ handleClose, show, details,address ,showCart, children }) => {
  details = JSON.parse(JSON.stringify(details).replace("quantity", "stockTotal"));
    const showHideClassName = show ? "mod display-block" : "mod display-none";
  if (Object.keys(details).length !== 0) {
    let images = [];
    details.itemImagesUrl.map((i) => {
      images.push({ src: i });
    });
   
   

   
    return (
      <div className={showHideClassName}>
        <span className="topright">
          <Button
            type="primary"
            shape="circle"
            style={{ width: "auto" }}
            onClick={handleClose}
          >X</Button>
        </span>
        <section className="mod-main">
          <Row className="row">
            <Col flex="1 0 50%" className="column ">
              <Carousel images={images} className="im" canAutoPlay={false} />
            </Col>

            <Col flex="1 0 50%" className="column ">
              <div className="mod-content ml-3 mr-3 mt-4">
                <div style={{ display: "grid" }}>
                  <span className="mod-title" style={{ fontSize: 30 }}>
                    {details.itemTitle}
                  </span>
                  <div className="mt-2">
                    {details.itemDiscountedPrice < details.itemOriginalPrice &&
                    details.itemDiscountedPrice !== 0 ? (
                      <span className="mod-subtitle mt-2 ">
                        {" "}
                        <span
                          className=""
                          style={{
                            color: "black",
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          ${details.itemDiscountedPrice}
                        </span>
                        {"  "}
                        <strike style={{ color: "#585858	", fontSize: 20 }}>
                          {" "}
                          ${details.itemOriginalPrice}
                        </strike>{" "}
                      </span>
                    ) : (
                      <span className="mod-subtitle mt-2">
                        {" "}
                        ${details.itemOriginalPrice}{" "}
                      </span>
                    )}
                  </div>
                  {details.storeName && (
                    <span className="mod-subtitle mt-4">
                      Store Name:{" "}
                      <span style={{ color: "#585858	", fontWeight: 400 }}>
                        {details.storeName}
                      </span>
                    </span>
                  )}

                  <span className="mod-subtitle mt-2">
                    Barcode:{" "}
                    <span style={{ color: "#585858	", fontWeight: 400 }}>
                      {details.itemBarcode}
                    </span>
                  </span>
                  <span className="mod-subtitle mt-2">
                    Category:{" "}
                    <span style={{ color: "#585858	", fontWeight: 400 }}>
                      {details.itemCategory}
                    </span>
                  </span>
                  <span className="mod-subtitle mt-2">
                    Condition:{" "}
                    <span style={{ color: "#585858	", fontWeight: 400 }}>
                      {details.itemCondition}
                    </span>
                  </span>

                  <span className="mod-title mt-3">Product Details</span>
                  <p className=" mt-3 mod-text">{details.itemDescription}</p>
                  {showCart && (
              
                    <AddToCart
                      details={details}
                      address={address}
                      showDelete={false}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    );
  } else {
    return(<div className={showHideClassName}>
      <section className="mod-main">Error happened</section>
    </div>);
  }
 
};
export default MyModal;
