import React, {useState} from 'react';

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useCart } from "react-use-cart";

import MyButton from "../../shared-components/MyButton";
import Utils from "utils";
import { message } from 'antd';
const AddToCart = (props) => {
    let { details, address, showDelete, amount } = props;
    details = JSON.parse(
      JSON.stringify(details).replace("itemQuantity", "stockTotal")
    );
      const [cartTotal, setCartTotal] = useState(showDelete?amount:1);
    const { addItem, items, getItem, emptyCart, updateItem } = useCart();
      const onCartChange = (action) => {
        if (cartTotal === 1 && action === "minus") {
            return;
        }
        if (action === "plus" && details.stockTotal === cartTotal) {
          return message.info("Not enough stock");
          }
        
        if (action === "plus") {
            setCartTotal((x) => x + 1);
              if (showDelete) {
               addToCart()
              }
        }
        if (action === "minus") {
          setCartTotal((x) => x - 1);
        }
    };
       const checkIfInStock = (currentAmount) => {
           let total = currentAmount + cartTotal;
           console.table(details.stockTotal, total);
         if (total > details.stockTotal) {
           return false;
         } else {
           return true;
         }
       };
    const incrementCart = () => {
        insertItem(details.quantity);
        

       }
       const insertItem = (currentTotal,a) => {
         if (checkIfInStock(currentTotal)) {
           addItem(
             {
               id: details.productID,
               price: 2,
               updatedAt: new Date(),
               seller: details.storeName,
             },
             a?a:cartTotal
           );
           updateItem(details.productID, {
             updatedAt: new Date(),
           });
           return message.success(
             `${cartTotal} ${details.itemTitle} added to your cart`
           );
         } else {
           return message.info(
             `Amount requested is greater than the current stock`
           );
         }
       };
       const addToCart = () => {
         //  console.log(getItem(details.productID))
         // emptyCart()
         // Utils.filterProductByNetworks()
         if (
           !Utils.filterProductByNetworks(
             address.country,
             address.province,
             address.city,
             details
           )
         ) {
           return message.info("Out of your delivery area");
         }
         let currentCart = getItem(details.productID);
         if (currentCart === undefined) {
           insertItem(0);
         } else {
           insertItem(currentCart.quantity);
         }
         // if (
         //   currentCart.quantity === details.stockTotal &&
         //   currentCart.quantity
         // ) {
         //   return message.info(`${details.title} is out of stock`);
         // } else {
         //   addItem(
         //     {
         //       id: details.productID,
         //       price: 2,
         //       updatedAt: new Date(),
         //     },
         //     cartTotal
         //   );
         //   return message.success(
         //     `${cartTotal} ${details.title} added to your cart`
         //   );
         // }
       };
    return (
      <div className="mt-3 mb-3" style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            marginRight: 5,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
          className="borderGray300"
        >
          <div
            style={{ flex: 1, height: "100%" }}
            className="minuContainer"
            onClick={() => {
              onCartChange("minus");
            }}
          >
            <span className="minusButton">
              <MinusOutlined />
            </span>
          </div>
          <div style={{ flex: 1, fontSize: 25, color: "black" }}>
            {cartTotal}
          </div>
          <div
            style={{ flex: 1, height: "100%" }}
            className="minuContainer plusContainer"
            onClick={() => {
              onCartChange("plus");
            }}
          >
            <span className="minusButton ">
              <PlusOutlined />
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          {!showDelete && (
            <MyButton
              text="Add to cart"
              type="primary"
              onClick={addToCart}
              style={{ width: "100%", margin: 0 }}
            />
          )}
        </div>
      </div>
    );
}

export default AddToCart;