/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,

  Modal,
  Input,
  Form,
  message,
  notification,
  Cascader,
  Tooltip,
  Tabs,
} from "antd";
import { Icon } from "components/util-components/Icon";



import {
  MailOutlined,
  QuestionCircleOutlined,
  PhoneOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,

  BookOutlined,
} from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import Flex from "components/shared-components/Flex";
import MyButton from "components/shared-components/MyButton";
import { auth, db } from "auth/FirebaseAuth";
import Utils from "utils";
import { useHistory } from "react-router-dom";
import moment from "moment";
const { TextArea } = Input;
const { TabPane } = Tabs;

const ProfileInfo = (props) => {
    const [showModal, setShowModal] = useState(false);
  let { userData } = props;
  return (
    <Card>
      <EditProfileModal
        visible={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        userData={userData}
      />

      <Row justify="center">
        <Col sm={24} md={23}>
          <div className="d-md-flex">
            <div className="ml-md-4 w-100">
              <Flex
                alignItems="center"
                mobileFlex={false}
                className="mb-3 text-md-left text-center"
              >
                <div style={{ flex: 1 }}>
                  <h2 className="mb-0">{userData.customerFullName}</h2>
                </div>
                <div style={{ flex: 1, textAlign: "end" }}>
                  <MyButton
                    onClick={() => {
                      setShowModal(true);
                    }}
                    type="primary"
                    shape="circle"
                    style={{ marginTop: 10 }}
                    icon={<EditOutlined />}
                    size="small"
                  />
                </div>
              </Flex>
              <Row gutter="16">
                <Col xs={24} sm={24} md={8}>
                  <Row className="mb-2">
                    <Icon
                      type={MailOutlined}
                      className="text-primary font-size-md"
                    />
                    <span className="text-muted ml-2">
                      Email: {userData.customerEmail}
                    </span>
                  </Row>
                  <Row>
                    <Icon
                      type={PhoneOutlined}
                      className="text-primary font-size-md"
                    />
                    <span className="text-muted ml-2">
                      Phone:{" "}
                      <span className="font-weight-semibold">
                        {userData.customerMobile}
                      </span>
                    </span>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
const checkIfEmailExsist = (email) => {
  db.collection("customers")
    .where("customerEmail", "==", email)
    .get()
    .then((docs) => {
      if (docs.empty) {
        return false;
      } else {
        return true;
      }
    });
}  

const EditProfileModal = (props) => {
  let { visible, onOk,userData  } = props;

  const [form] = Form.useForm();
  const [type, setType] = useState('nameEmail');
  const [email, setEmail] = useState(userData.customerEmail);
  useEffect(() => {
    form.setFieldsValue({
      mobileNumber: userData.customerMobile,
      email: userData.customerEmail,
      fullName: userData.customerFullName,
    });
  }, []);

  const changePassword = (email) => {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          // ..
          return notification["success"]({
            message: "We have sent you an email",
            description:
              "Please check your email & follow instructions to recover your password",
          });
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/user-not-found") {
            return message.error("Email not found");
          } else {
            return message.error(errorMessage);
          }

          // ..
        });
  }
  const onFinish = (values) => {
    if (type === "nameEmail") {
      db.collection("customers")
        .doc(userData.id)
        .update({
          customerFullName: values.fullName,
          customerMobile: values.mobileNumber,
        })
        .then(() => {
          onOk();
          return message.success("Successfull");
        });
      
    }
    if (type === "changeEmail") {
      if (values.email === email) {
        return message.info("You must choose different email")
      }
      if (values.password === "") {
        return message.info("Please enter your password");            
      }
      auth.signInWithEmailAndPassword(email, values.password).then((res) => {
        let emailExsists = checkIfEmailExsist(values.email);
        if (emailExsists) {
          return message.error("this email already exsists on our systems")
        }
        else {
            auth.currentUser
              .updateEmail(values.email)
              .then((done) => {
                db.collection("customers")
                  .doc(userData.id)
                  .update({ customerEmail: values.email });
                setEmail(values.email);
                onOk();
                return message.success("Email updated");

              
              })
              .catch((e) => {
                return message.error(e.message);
              });
        }
      
      }).catch((e) => {

        return message.error(e.message)
      })


    }
  } 
  return (
    <Modal
      title="Edit info"
      visible={visible}
      footer={[""]}
      onCancel={() => {
        onOk();
      }}
    >
      {type === "nameEmail" && (
        <Form
          name="normal_login"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div>
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Please enter your full name",
                  type: "string",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter your mobile email"
              />
            </Form.Item>
            <Form.Item
              name="mobileNumber"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                type="number"
                placeholder="Enter your mobile number"
              />
            </Form.Item>
            <Form.Item>
              <a
                className="login-form-forgot"
                onClick={() => {
                  setType("changeEmail");
                }}
              >
                Change email
              </a>
              <a
                className="login-form-forgot ml-2"
                onClick={() => {
                  changePassword(email);
                }}
              >
                Change Password
              </a>
            </Form.Item>

            <Form.Item>
              <MyButton text="Submit" block type="primary" htmlType="submit" />
            </Form.Item>
          </div>
        </Form>
      )}
      {type === "changeEmail" && (
        <Form
          name="normal_login"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  type: "string",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Enter your email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  message: "Password must be at least 6 characters ",
                  min: 6,
                },
              ]}
              hasFeedback
            >
              <Input.Password aria-label="password" />
            </Form.Item>
            <Form.Item>
              <a
                className="login-form-forgot"
                onClick={() => {
                  setType("nameEmail");
                }}
              >
                change basic info
              </a>
              <a
                className="login-form-forgot ml-2"
                onClick={() => {
                  changePassword(email);
                }}
              >
                Change Password
              </a>
            </Form.Item>

            <Form.Item>
              <MyButton text="Submit" block type="primary" htmlType="submit" />
            </Form.Item>
          </div>
        </Form>
      )}
    </Modal>
  );
}

const Orders = (props) => {
  let { orders, userData } = props;
  let history = useHistory();
    return (
      <Card title="Orders">
        <div className="mb-3">
          <Row>
            <Col sm={24} md={22}>
                        {orders.length === 0 &&
              
              <h2 className="text-center">No orders yet <a onClick={()=>history.push('/')}>order now</a></h2>
              }
              {orders.map((elm, i) => {
                return (
                  <div
                    className={`${i === orders.length - 1 ? "" : "mb-4"}`}
                    key={`eduction-${i}`}
                  >
                    <AvatarStatus
                      src={elm.itemImage}
                      name={elm.itemTitle}
                      subTitle={moment(elm.createdAt.toDate()).format("DD-MM-YYYY")}
                    />
                    <p className="pl-5 mt-2 mb-0 avatar-status-name">
                      Quantity:{" "}
                      <span className="text-muted">{elm.orderQuantity}</span>
                    </p>
                    <p className="pl-5 mt-2 mb-0 avatar-status-name">
                      Total: ${elm.orderSubTotalPrice}
                    </p>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      </Card>
  );
}


const AddAddressModal = (props) => {
  let { visible, onOk, mode, data, mainData, id, countr } = props;
  // let form = Form.useForm();
  const [cassData, setCassData] = useState([]);
  useEffect(() => {

    // var t0 = performance.now();

    // const countries = Utils.getAllCountries();

    // var t1 = performance.now();
    // console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
    // setCassData(countries);
  },[])
  const onFinish = (values) => {
    if (mode === "add") {
      mainData.push({
        address: values.address,
        shippingInstructions: values.instructions,
        postalCode: values.postalcode === undefined ? "" : values.postalcode,
        main: false,
        country: values.residence[0],
        province: values.residence[1],
        city: values.residence[2],
      });
  
      db.collection("customers")
        .doc(id)
        .update({ customerAddresses: mainData })
        .then(() => {
          onOk();
          return message.success("Address Added successfuly");
        });
    }
    else {
      mainData[data.index] = {
        address: values.address,
        shippingInstructions: values.instructions,
        postalCode: values.postalcode === undefined ? "" : values.postalcode,
        main: data.main,
        country: values.residence[0],
        province: values.residence[1],
        city: values.residence[2],
      };
       db.collection("customers")
         .doc(id)
         .update({ customerAddresses: mainData })
         .then(() => {
           onOk();
           return message.success("Address updated successfuly");
         });
    }
  }


  return (
    <Modal
      title="Edit info"
      visible={visible}
      footer={[""]}
      onCancel={() => {
        onOk();
      }}
    >
      {mode === "add" && (
        <Form
          // {...formItemLayout}
          layout="vertical"
          name="register"
          onFinish={onFinish}
        >
          <Form.Item
            name="residence"
            label={
              <span>
                Residence&nbsp;
                <Tooltip title="What is you current Country, Province & City?">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                type: "array",
                required: true,
                message: "Please select your habitual residence!",
              },
            ]}
          >
            <Cascader placeholder="Country/Province/City" options={countr} />
          </Form.Item>
          <Form.Item
            name="postalcode"
            label="postal code"
            rules={[
              {
                required: true,
                type: "string",
                message: "The enter your postal code",
              },
            ]}
          >
            <Input className="w-100" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                type: "string",
                message: "The enter your address",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="instructions"
            label="Shipping Instructions"
            rules={[
              {
                type: "string",
                message: "The enter your address",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <MyButton type="primary" text="Save" htmlType="submit" />
        </Form>
      )}
      {mode === "edit" && (
        <Form
          // {...formItemLayout}
          layout="vertical"
          name="register"
          onFinish={onFinish}
          initialValues={{
            residence: [data.country, data.province, data.city],
            postalcode: data.postalCode,
            address: data.address,
            instructions: data.shippingInstructions,
          }}
        >
          <Form.Item
            name="residence"
            label={
              <span>
                Residence&nbsp;
                <Tooltip title="What is you current Country, Province & City?">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                type: "array",
                required: true,
                message: "Please select your habitual residence!",
              },
            ]}
          >
            <Cascader placeholder="Country/Province/City" options={cassData} />
          </Form.Item>
          <Form.Item
            name="postalcode"
            label="postal code"
            rules={[
              {
                required: true,
                type: "string",
                message: "The enter your postal code",
              },
            ]}
          >
            <Input className="w-100" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                type: "string",
                message: "The enter your address",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="instructions"
            label="Shipping Instructions"
            rules={[
              {
                type: "string",
                message: "The enter your address",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <MyButton type="primary" text="Save" htmlType="submit" />
        </Form>
      )}
    </Modal>
  );
};
const MyAddresses = (props) => {
  let { address, id, countr } = props;
  
  const sortAddress = () => {
       address.sort(function (x, y) {
         // true values first
         return x.main === y.main ? 0 : x.main ? -1 : 1;
         // false values first
         // return (x === y)? 0 : x? 1 : -1;
       });
  }
  sortAddress()
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [data, setData] = useState({});
  const makePrimary = (index) => {
    address[0].main = false;
    address[index].main=true;
    sortAddress();
   db.collection("customers")
     .doc(id)
     .update({ customerAddresses: address })
     .then(() => {
       return message.success("successful");
     });

  }
  const onOk = () => { setVisible(false) };

    return (
      <Card
        title={
          <div style={{ display: "flex" }}>
            <AddAddressModal
              countr={countr}
              visible={visible}
              onOk={onOk}
              id={id}
              mode={mode}
              data={data}
              mainData={address}
            />
            <span className="ant-card-head-title">My Addresses</span>
            <MyButton
              onClick={() => {
                setMode("add");
                setVisible(true);
              }}
              type="primary"
              shape="circle"
              style={{ marginTop: 10 }}
              icon={<PlusOutlined />}
              size="small"
            />
          </div>
        }
      >
        <div className="mb-3">
          <Row>
            <Col sm={24} md={22}>
              {address.map((elm, i) => {
                return (
                  <div
                    className={`${i === address.length - 1 ? "" : "mb-4"}`}
                    key={`eduction-${i}`}
                  >
                    <div style={{ display: "grid" }}>
                      <div>
                        <span className="avatar-status-name">
                          {elm.country}, {elm.province}, {elm.city},{" "}
                          {elm.postalCode}
                        </span>
                        {i === 0 && (
                          <span className="avatar-status-name ml-2">
                            (Primary address)
                          </span>
                        )}
                        {i !== 0 && (
                          <a
                            className=" ml-2"
                            onClick={() => {
                              makePrimary(i);
                            }}
                          >
                            Make Primary
                          </a>
                        )}
                        <EditOutlined
                          className="ml-2"
                          onClick={() => {
                            setMode("edit");
                            setData({ ...elm, index: i });
                            setVisible(true);
                          }}
                        />
                      </div>

                      <span className="font-weight-semibold">
                        Address: {elm.address}
                      </span>
                      <span className="text-muted avatar-status-subtitle">
                        Instructions: {elm.shippingInstructions}
                      </span>
                    </div>
                  </div>
                );
              })}
            </Col>
            <div style={{ textAlign: "right" }}></div>
          </Row>
        </div>
      </Card>
    );}


const Profile = (props) => {
    let { user, userData } = props;
    const avatarSize = 150;
  let [orders, setOrders] = useState([]);
  const [countr, setcountr] = useState([])
    const [matchs, setMatchs] = useState(
      window.matchMedia("(min-width: 768px)").matches
    );
  let history= useHistory()

  const [userInfo, setUserInfo] = useState(userData);
  useEffect(() => {
    if (user===null) {
      history.push('/')
    }
       const countries = Utils.getAllCountries();

       setcountr(countries);
        async function getOrders() {
            let list = [];
               let ordersQuery = await db
                 .collection("orders")
                 .where("customerID", "==", userData.id).limit(3)
                 .get();
            ordersQuery.forEach((doc) => {
                list.push({ ...doc.data(),id:doc.id });
            });
        setOrders(list);


      }
    let customerListener = db.collection('customers').doc(userData.id).onSnapshot((snap) => {
        setUserInfo({...snap.data(),id:snap.id});
      })
      getOrders();
       const handler = (e) => setMatchs(e.matches);
       window.matchMedia("(min-width: 768px)").addEventListener("change",handler);
      return () => {
        customerListener();
      }
      
    }, [])
  return (
    <div style={{ marginTop: 100 }}>
      {/* <div className="container my-4">
        <ProfileInfo userData={userInfo} />
        <Row gutter="16">
          <div style={{ padding: 10, width: "100%" }}>
            <MyAddresses
              address={userData.customerAddresses}
              id={userData.id}
            />
          </div>
        

          <Col xs={24} sm={24} md={16}>
            <Orders />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <MyAddresses />
          </Col>
        </Row>
      </div> */}
      <Row className="row padder">
        <Tabs
          tabBarStyle={{ backgroundColor: "white" }}
          defaultActiveKey="1"
          style={{ width: "100%" }}
          tabPosition={matchs ? "left" : "top"}
        >
          <TabPane
            tab={
              <span>
                <UserOutlined />
                Profile Info
              </span>
            }
            key="1"
          >
            <ProfileInfo userData={userInfo} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <BookOutlined />
                Address Book
              </span>
            }
            key="2"
          >
            <MyAddresses
              countr={countr}
              address={userData.customerAddresses}
              id={userData.id}
            />
          </TabPane>
        </Tabs>
      </Row>
    </div>
  );
};

export default Profile;
