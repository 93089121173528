import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Select, } from "antd";
import { db,  } from "../../../auth/FirebaseAuth";
import Utils from "utils";
import { useHistory } from "react-router";
import MyButton from '../../../components/shared-components/MyButton';
import '../products/layout.css';
const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const Home = ({user}) => {
	let history = useHistory();
  const [filter, setFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    country: "",
    state: "",
    city: "",
  });
  const [currentState, setCurrentState] = useState([]);
  const [currentCity, setCurrentCity] = useState([]);
  const maxColumns = 4;

  
  useEffect(() => {
    if (user !== null) {
      console.log(user,'froom hom')
      history.push("/products")
    }
    async function getNetworks() {
      let networksQuery = await db.collection("networks").get();
      let list = [];
      networksQuery.forEach((doc) => {
        list.push({ ...doc.data() });
      });
		setFilter(list);
		
    }

    getNetworks();
  }, []);

	const onCountryChange = (e) => {
		let list = Utils.filterArray(filter, 'country', e);
		let states = [];
		list[0].stateCity.map((i) => {
      states.push(i.state);
    });
		setCurrentState(states);
		setCurrentCity([]);
		setSelectedFilter({'country':e,city:'',state:''})
  };
	const onStateChange = (e) => {
    
		let mainList = Utils.filterArray(filter, "country", selectedFilter.country);
		let list = Utils.filterArray(mainList[0].stateCity, "state", e);

		setCurrentCity(list[0].cities)
    setSelectedFilter({ ...selectedFilter, 'state': e,'city':'' });
	};
	
const	onCityChange = (e) => {
		setSelectedFilter({...selectedFilter,'city':e})
  }
    const style = {
      main: {
        width: 1600,
        border: "5px solid black",
        display: "flex",
        //'justify-content': 'space-around',
        "flex-wrap": "wrap",
      },
    };
  return (
    <div className="cont" style={{ marginTop: 100 }}>
      <Row className="row" style={{ width: "100%", margin: "auto" }}>
        <Col flex="1 0 70%" className="column Red">
          <div style={{ textAlign: "center", width: "100%" }}>
            <Title style={{ fontSize: 40, fontWeight: "bold", color: "black" }}>
              Explore all around you
            </Title>
            <Title level={2}>
              Basicly, explore all<br></br>product from your home
            </Title>
            <Title level={4}>No signup or account creation</Title>
            <img
              className="mt-4 resImg"
              src="https://firebasestorage.googleapis.com/v0/b/aspect-e0f56.appspot.com/o/stores%2FScreen%20Shot%202021-06-06%20at%201.05.20%20PM.png?alt=media&token=4a841691-96a9-4f82-855b-7fa0fa201061"
            />
          </div>
        </Col>
        <Col
          flex="1 0 30%"
          className="column Green"
          style={{ alignItems: "flex-end" }}
        >
          <div className="mt-3">
            <Paragraph>Country</Paragraph>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a country"
              optionFilterProp="children"
              value={selectedFilter.country}
              onChange={onCountryChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {filter.map((i) => {
                return (
                  <Option value={i.country} key={i.country}>
                    {i.country}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mt-3">
            <Paragraph>Province</Paragraph>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a person"
              optionFilterProp="children"
              value={selectedFilter.state}
              onChange={onStateChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {currentState.map((i) => {
                return (
                  <Option value={i} key={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mt-3">
            <Paragraph>City</Paragraph>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a city"
              optionFilterProp="children"
              value={selectedFilter.city}
              onChange={onCityChange}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {currentCity.map((i) => {
                return (
                  <Option value={i.name} key={i.name}>
                    {i.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mt-3 text-center">
            <MyButton
              block
              type="primary"
              shape="round"
              text="Explore"
              onClick={() => {
                history.push(
                  `/products?country=${selectedFilter.country}&province=${selectedFilter.state}&city=${selectedFilter.city}`
                );
              }}
            />
          </div>
          <div className="mt-3 text-left">
            <Text style={{ fontSize: 40, fontWeight: "bold", color: "black" }}>
              Become a store seller
            </Text>
          </div>
          <div className="mt-3 text-left" style={{ display: "grid" }}>
            <Text style={{ fontSize: 20, fontWeight: "400", color: "black" }}>
              Create your own account in marketplace
            </Text>
            <Text style={{ fontSize: 20, fontWeight: "400", color: "black" }}>
              Listing your products
            </Text>
            <Text style={{ fontSize: 20, fontWeight: "400", color: "black" }}>
              React more customers
            </Text>
            <Text style={{ fontSize: 20, fontWeight: "400", color: "black" }}>
              Grow your business
            </Text>
          </div>
          <div className="mt-3 text-center">
            <MyButton block type="primary" shape="round" text="Register now" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
