import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Tooltip,Spin, } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

const ImageSlider = ({ images }) => {
    const [activeImage, setactiveImage] = useState("");
  const [selectedIndex, setselectedIndex] = useState(0);
  const [loading, setloading] = useState(false)
  useEffect(() => {
    setactiveImage(images[0].src);
    setloading(false)
  }, [images]);
    const changeImage = (i) => {
        setactiveImage(i);
  }
  const goNext = () => {
    //check if it's the last index
    let lastIndex = images.length - 1;

    //last index
    if (selectedIndex === lastIndex) {
      setselectedIndex(0);
      setactiveImage(images[0].src);
      return
    }
    let newIndex = selectedIndex+1;
    setselectedIndex(newIndex);
    setactiveImage(images[newIndex].src);
  }
   const goBack = () => {
     //check if it's the last index
     let lastIndex = images.length - 1;

     //last index
     if (selectedIndex === 0) {
       setselectedIndex(lastIndex);
       setactiveImage(images[lastIndex].src);
       return;
     }
     let newIndex = selectedIndex - 1;
     setselectedIndex(newIndex);
     setactiveImage(images[newIndex].src);
   };
    return (
      <div className="slider-container">
        {loading ? (
          <div style={{ justifyContent: "center",flex:1 }}>
            <Spin tip="Loading..." />
          </div>
        ) : (
          <>
            {" "}
            <div className="vertical-images-container">
              {images.map((i, index) => {
                return (
                  <div
                    className="vertical-images-box"
                    key={index}
                    onClick={() => {
                      changeImage(i.src);
                      setselectedIndex(index);
                    }}
                  >
                    <img
                      src={i.src}
                      className={`vertical-images ${
                        activeImage === i.src ? "vertical-active" : ""
                      }`}
                    />
                  </div>
                );
              })}
            </div>
            <div className="images-slider-container">
              <div className="caro-left">
                <Tooltip title="Back">
                  <Button
                    shape="circle"
                    onClick={goBack}
                    icon={<LeftOutlined />}
                  />
                </Tooltip>
              </div>
              <div className="caro-center">
                <img
                  src={activeImage}
                  width="100%"
                  className="caro-image"
                  height="400"
                />
              </div>
              <span className="caro-right">
                <Tooltip title="Forward">
                  <Button
                    shape="circle"
                    onClick={goNext}
                    icon={<RightOutlined />}
                  />
                </Tooltip>
              </span>
            </div>
          </>
        )}
      </div>
    );
}

export default ImageSlider