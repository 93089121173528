import React, { useEffect, useState } from "react";
import { Typography, Tag, Button, Collapse, Tooltip, message } from "antd";
import './productDetails.css'
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Loading from "components/shared-components/Loading";
import { useCart } from "react-use-cart";

import { db } from "../../../auth/FirebaseAuth";
import {  useParams } from "react-router-dom";
import ImageSlider from "components/shared-components/ImageSlider";
import Utils from "utils";
import {auth} from '../../../auth/FirebaseAuth'
const { Text } = Typography;
const { Panel } = Collapse;

const ProductDetails = ({userData}) => {
  let { id } = useParams();
      const { addItem, items, getItem, emptyCart, updateItem } = useCart();

  const [images, setImages] = useState([]);
    const [product, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [stock, setstock] = useState({ color: "", text: "" });
  const [cartDisabled, setcartDisabled] = useState({
    state: true,
    message: "Available",
  });
  const checkIfInNetwork = async (item, email) => {
    let data = await db
      .collection("customers")
      .where("customerEmail", "==", email).get();
    let userNetwork=''
    data.forEach((doc) => {
      console.log(doc.data(),'heheheh')
      userNetwork = doc.data().customerAddresses[0];
    })
    if (Utils.filterProductByNetworks(userNetwork.country, userNetwork.province, userNetwork.city, item)) {
      console.log('ttrue')
      setcartDisabled({state:false,message:'Out of delivery area'})
      
    }
   
  };
  console.log(userData)
  const getProcuctStock = (i) => {
      if (i <= 10) {
        setstock({ color: "volcano", text: "Low in stock" });
              setcartDisabled({ state: false, message: "Harry up item will run out soon" });

      }
      if (i === 0) {
        setstock({ color: "magenta", text: "Unavailable" });
        setcartDisabled({ state: true, message: "Item Unavailable" });
      }
      if (i > 11) {
        setstock({ color: "#87d068", text: "Available" });
                      setcartDisabled({
                        state: false,
                        message: "Item available",
                      });

      }
  }
  useEffect(() => {
    let listener= db.collection("items")
       .doc(id)
       .onSnapshot(async(query) => {
         let imagesArray = [];
         query.data().itemImagesUrl.map((i) => {
           imagesArray.push({ src: i });
         });
         let storeInfo = await db
           .collection("stores")
           .doc(query.data().storeID)
           .get();
        //  getProcuctStock(query.data().itemQuantity);
         let user = auth.currentUser
         console.log(user,'cureeen')
         if (user !== null) {

           checkIfInNetwork(query.data(), user.email);
         }
         setImages(imagesArray);
         setProducts({
           ...query.data(),
           storeName: storeInfo.data().storeName,
         });
         setLoading(false);
       });
    return()=>{listener();}
  }, [id]);
  const addToCart = () => {
     addItem(
       {
         id: id,
         price: getPrice(product.itemOriginalPrice, product.itemDiscountedPrice),
         updatedAt: new Date(),
         seller: product.storeName,
       },
       1
     );
     updateItem(id, {
       updatedAt: new Date(),
     });
    message.success(`${product.itemTitle} is added to your bag`)
  }
 
 if (loading) {
    return <Loading cover="content" />;
  }
  const getPrice = (og, dp) => {
    if (dp===0) {
      return og;
      

    }
    
    if (dp <= og) {
      return dp;
    }
    return og;

  }
 
  return (
    <div style={{ padding: "0 0 0 0", marginTop: 100 }}>
      <div className="slider-layout">
        <div className="product-left-container">
          <ImageSlider images={images} />
        </div>
        <div className="product-right-container">
          <h1>{product.itemTitle}</h1>
          <div className="product-price-container">
            <span className="product-right-price">
             CAD$ {getPrice(product.itemOriginalPrice,product. itemDiscountedPrice)}
            </span>
            {/* <Tag color={stock.color}>{stock.text}</Tag> */}
          </div>
          {/* <Tooltip title={cartDisabled.message}> */}
            <Button
              type="primary"
              disabled={cartDisabled.state}
              onClick={addToCart}
              className="mt-3"
              block
            >
              Add to cart
            </Button>
          {/* </Tooltip> */}

          <Collapse bordered={false} className="mt-3" defaultActiveKey={["2"]}>
            <Panel header="Highlights" style={{ borderBottomWidth: 0 }} key="1">
              <div style={{ display: "grid" }}>
                <span className="panel-details-text">
                  -Store: {product.storeName}
                </span>
                <span className="panel-details-text">
                  -Barcode: {product.itemBarcode}
                </span>
                <span className="panel-details-text">
                  -Category: {product.itemCategory}
                </span>
                <span className="panel-details-text">
                  -Condition: {product.itemCondition}
                </span>
              </div>
            </Panel>
            <Panel
              header="Description"
              style={{ borderBottomWidth: 0 }}
              key="2"
            >
              <span className="panel-desc">{product.itemDescription}</span>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
