import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Select,
  Card,

} from "antd";
import '../products/layout.css';
import "react-gallery-carousel/dist/index.css";
import Loading from "components/shared-components/Loading";
import MyModal from 'components/shared-components/MyModal/index'
import { db } from "../../../auth/FirebaseAuth";
import Utils from "utils";
import { useParams } from "react-router-dom";
import ResponsiveGrid from '../home/Panel';
const {  Text } = Typography;
const { Option } = Select;
const { Meta } = Card;
const StoreDetails = (props) => {
  let { userData, user } = props;
  let { id } = useParams();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
  const [store, setStore] = useState({});
    const [loading, setLoading] = useState(true);
    const [Categories, setCategories] = useState([]);
  const [cat, setCat] = useState('all');
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const getStore = async (id) => {
    let query = await db.collection("stores").doc(id).get();

    let productQuery = await db
      .collection("items")
      .where("storeID", "==", id)
      .get();
    let list = [];
    productQuery.forEach((doc) => {
      list.push({
        ...doc.data(),
        id: doc.id,
        productID: doc.id,
        storeName: query.data().storeName,
      });
    });
      let cats = await db.collection("settings").doc("categories").get();
      setCategories(cats.data().options);
      setFilteredProducts(list);
    setProducts(list);
    setStore({ ...query.data(), id: query.id });
    setLoading(false);
  };
  useEffect(() => {
    getStore(id);
  }, []);
    const onChangeCat = (e) => {
        if (e === "all") {
            setFilteredProducts(products);
            setCat(e)
            return;
        
        }
        else {
            let fil = Utils.filterArray(products, "itemCategory", e);
            setFilteredProducts(fil);
                        setCat(e);

            return
        }

  }
  const handleProductClick = (i) => {
      setSelectedProduct(i);
      setShowProductModal(true);
  }
  if (loading) {
    return <Loading cover="content" />;
  }
  return (
    <div style={{ padding: "0 0 0 0", marginTop: 100 }}>
      {/* {productModal()} */}
      <MyModal
        show={showProductModal}
        showCart={
          user && userData.customerAccountStatus === "verified" ? true : false
        }
        handleClose={() => {
          setShowProductModal(false);
        }}
        details={selectedProduct}
        address={userData ? userData.customerAddresses[0] : ""}
      />
      <Row className="row" style={{ padding: 30 }}>
        <Col flex="1 0 25%" className="column ">
          <Card
            className="infoCard"
            key="q"
            cover={
              <img
                alt="example"
                src={store.storeLogo}
                width={300}
                height={200}
              />
            }
          >
            <Meta
              title={store.storeName}
              description={
                <div>
                  <div style={{ display: "grid" }}>
                    <Text>{store.storeAddress}</Text>
                    <Text>{store.storeCity}</Text>
                    <Text>{store.storeProvince}</Text>
                    <Text>{store.storeCountry}</Text>
                    <Text>{store.storeMobile}</Text>
                  </div>
                </div>
              }
            />
          </Card>
          <Card className="infoCard" key="w">
            <div>
              <h2>Categories</h2>
              <Select
                style={{ width: "100%" }}
                value={cat}
                onChange={onChangeCat}
              >
                <Option value="all">All</Option>
                {Categories.map((i) => {
                  return <Option value={i.value}>{i.label}</Option>;
                })}
              </Select>
            </div>
          
          </Card>
        </Col>

        <Col flex="1 0 75%" className="column ">
          <ResponsiveGrid
            list={filteredProducts}
            onClick={handleProductClick}
          />
        </Col>
      </Row>
      {/* <Row
        style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30 }}
        gutter={16}
      >
        <Col xs={24} sm={24} md={7}>
          <Card
            style={{ width: 300 }}
            cover={
              <img
                alt="example"
                src={store.storeLogo}
                width={300}
                height={200}
              />
            }
          >
            <Meta
              title="test"
              description={
                <div>
                  <div style={{ display: "grid" }}>
                    <Text>{store.storeAddress}</Text>
                    <Text>{store.city}</Text>
                    <Text>{store.province}</Text>
                    <Text>{store.country}</Text>
                    <Text>{store.mobileNumber}</Text>
                  </div>
                </div>
              }
            />
          </Card>
          <Card style={{ width: 300 }}>
            <div>
              <h2>Categories</h2>
              <Select
                style={{ width: "100%" }}
                value={cat}
                onChange={onChangeCat}
              >
                <Option value="all">All</Option>
                {Categories.map((i) => {
                  return <Option value={i.value}>{i.label}</Option>;
                })}
              </Select>
            </div>
            <div className="mt-3">
              <h2>Price</h2>
              <Flex>
                <div>
                  <Text className="mr-2">From</Text>
                  <InputNumber
                    min={1}
                    max={10}
                    defaultValue={3}
                    style={{ width: "50%", marginRight: 5 }}
                  />
                </div>
                <div>
                  <Text className="mr-2">To</Text>
                  <InputNumber
                    min={1}
                    max={10}
                    defaultValue={3}
                    style={{ width: "50%" }}
                  />
                </div>
              </Flex>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={17}>
          <ResponsiveGrid
            list={filteredProducts}
            onClick={handleProductClick}
          />
        </Col>
      </Row> */}
    </div>
  );
};

export default StoreDetails;
