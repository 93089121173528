import React, {useEffect, useState, useCallback} from 'react';
import { connect } from 'react-redux';

import Loading from 'components/shared-components/Loading';
import HeaderNav from 'components/layout-components/HeaderNav';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import AuthViews from 'views/auth-views';
import {
  Layout,
  Grid,
} from "antd";
import { db, auth } from '../../auth/FirebaseAuth';
import { 
  SIDE_NAV_WIDTH, 
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction,  }) => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
    const handleClick = useCallback(async (currentUser) => {
      if (currentUser) {
        console.log('first')
            let customerQuery = await db
              .collection("customers")
              .where("customerEmail", "==", currentUser.email)
          .get();

              customerQuery.forEach((doc) => {
                setUserData({ ...doc.data(), id: doc.id });
              });

            setUser(currentUser);
          }
    }, []);

  useEffect(() => {
      /* your authentication and state update code */
        let authListener = auth.onAuthStateChanged(handleClick);
  
    return () => {
      authListener();
    };
  }, []);
  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if(direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }  
    if(direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }
     return (
       <Layout style={{ padding: 0, }}>
         <HeaderNav isMobile={isMobile} user={user} userData={userData} />
         <Layout className="app-container">
           <Layout className="app-layout">
             <div
               className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}
               style={{ marginTop: 0, padding: 0 }}
             >
               <Content>
                 <AppViews user={user} userData={userData} />
               </Content>
             </div>
             <Footer />
           </Layout>
         </Layout>
       </Layout>
     );
 
  
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));