/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Form, Input,  Modal, message, notification } from "antd";
import { MailOutlined, LockOutlined} from "@ant-design/icons";
import { auth } from '../../../auth/FirebaseAuth';
import MyButton from "../MyButton";

const SignIn = (props) => {
  const { show, onOk, onCancel } = props;
  const [type, setType] = useState("signIn");
  const [data, setData] = useState({});
  const onFinish = (values) => {
    if (type==="signIn") {
         auth
           .signInWithEmailAndPassword(values.email, values.password)
           .then((res) => {
           
             
             onOk();
           })
           .catch((e) => {
             if (e.code === "auth/wrong-password") {
               return message.error("incorrect password");
             }
             if (e.code === "auth/user-not-found") {
               return message.error("Account does not exist please sign up");
             }
           });
    }
    else {
      setData(values);
      sendEmail(values);
    }
   
  };
  const sendEmail = (values) => {
    if (Object.keys(values).length === 0 && values.constructor === Object) {
      return message.error('Please enter valid email');
    }
    else {
        auth
          .sendPasswordResetEmail(values.email)
          .then(() => {
            // Password reset email sent!
            // ..
            return notification["success"]({
              message: "We have sent you an email",
              description:
                "Please check your email & follow instructions to recover your password",
            });
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === "auth/user-not-found") {
              return message.error("Email not found");
            } else {
              return message.error(errorMessage);
            }

            // ..
          });
    }
       
  }

  return (
    <Modal
      title="Sign in"
      footer={[""]}
      visible={show}
      onOk={onOk}
      onCancel={onCancel}
    >
      {type === "signIn" && (
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter a valid Email!",
                type: "email",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <a
              className="login-form-forgot"
              onClick={() => {
                setType("forgotPassword");
              }}
            >
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <MyButton text="Log in" block type="primary" htmlType="submit" />
            <br></br> <br></br> Or <a href="/register">register now!</a>
          </Form.Item>
        </Form>
      )}
      {type === "forgotPassword" && (
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter a valid Email!",
                type: "email",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item>
            <a
              className="login-form-forgot"
              style={{ marginRight: 10 }}
              onClick={() => {
                setType("signIn");
              }}
            >
              Back to sign in
            </a>
            <a
              className=""
              onClick={() => {
                sendEmail(data);
              }}
            >
             
              Resend email
            </a>
          </Form.Item>

          <Form.Item>
            <MyButton text="Verify" type="primary" htmlType="submit" />
            <br></br> <br></br> Or <a href="/register">register now!</a>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default SignIn;
