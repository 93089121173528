const FirebaseConfig = {
  apiKey: "AIzaSyC33X_EWgzdizSXZ0z0gFru1F-xLwvR7Hw",
  authDomain: "aspect-e0f56.firebaseapp.com",
  databaseURL: "https://aspect-e0f56.firebaseio.com",
  projectId: "aspect-e0f56",
  storageBucket: "aspect-e0f56.appspot.com",
  messagingSenderId: "354031691594",
  appId: "1:354031691594:web:91fd02ed92bfd597cf03b3",
  measurementId: "G-8G5CTNKM7R",
};

export default FirebaseConfig;
