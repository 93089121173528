import { message } from 'antd';
import { db } from 'auth/FirebaseAuth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const FinishSignUp = (props) => {
    let { user, userData } = props;
    let history = useHistory();
    useEffect(() => {
        db.collection('customers').doc(userData.id).update({ signupComplete: true }).then(() => {
            message.success('Account Verifed');
            history.push('/')
        });
    },[])
   return(<h1 className="text-center" style={{ marginTop:100 }}>Redirecting</h1>)
}

export default FinishSignUp;