import React from "react";
import { Button } from 'antd';
import './myButton.css'

const MyButton = (props) => {
    let { text } = props;
  return (
    <Button className="def-button" {...props}>
      {text}
    </Button>
  );
};


export default MyButton;
