import React, { useEffect, useState } from "react";
import { Col, Row, Typography,  Select,  Card, Modal } from "antd";
import Loading from "components/shared-components/Loading";
import SubNav from "components/shared-components/SubNav";
import { db } from "../../../auth/FirebaseAuth";
import Utils from "utils";
import "../home/panel.css";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import "../products/layout.css";
const { Title, Paragraph} = Typography;
const { Option } = Select;
const { Meta } = Card;
const BrowseStores = (props) => {
  let { user, userData } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get("country");
  const state = urlParams.get("province");
  const city = urlParams.get("city");

  const [filter, setFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    country: country,
    state: state,
    city: city,
  });
  const [currentState, setCurrentState] = useState([]);
  const [currentCity, setCurrentCity] = useState([]);
  const [stores, setStores] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const getStores = (country, state, city) => {
  
     
   

    db.collection("stores")
      .get()
      .then((q) => {
        let l = [];
        q.forEach((doc) => {
          l.push({ ...doc.data(), id: doc.id });
        });
        setAllStores(l);
        filterByNetWork(l, country, city, state);
        setLoading(false);
      });
  };
  const filterByNetWork = (list,country,city,state) => {
    let currentStores = [];
    list.map((i) => {
      if (i.storeNetworks) {
        if (Utils.filterByStoreNetwork(country, state, city, i)) {
          currentStores.push({ ...i });
        }
      }
    });
    setStores(currentStores);

  }
  useEffect(() => {
    async function getNetworks() {
      let networksQuery = await db.collection("networks").get();
      let list = [];
      networksQuery.forEach((doc) => {
        list.push({ ...doc.data() });
      });
      setFilter(list);
    }
      if (user) {
        let mainUserAddress = Utils.filterArray(
          userData.customerAddresses,
          "main",
          true
        );
       
      
        setSelectedFilter({
          country: mainUserAddress[0].country,
          state: mainUserAddress[0].province,
          city: mainUserAddress[0].city,
        });
            getStores(
              mainUserAddress[0].country,
              mainUserAddress[0].province,
              mainUserAddress[0].city
            );

    }
      else {
            getStores(country, state, city);

    }
    getNetworks();
  }, []);

  const onCountryChange = (e) => {
    let list = Utils.filterArray(filter, "country", e);
    let states = [];
    list[0].stateCity.map((i) => {
      states.push(i.state);
    });
    setCurrentState(states);
    setCurrentCity([]);
    setSelectedFilter({ country: e, city: "", state: "" });
  };
  const onStateChange = (e) => {
    let mainList = Utils.filterArray(filter, "country", selectedFilter.country);
    let list = Utils.filterArray(mainList[0].stateCity, "state", e);

    setCurrentCity(list[0].cities);
    setSelectedFilter({ ...selectedFilter, state: e, city: "" });
  };

  const onCityChange = (e) => {
    setSelectedFilter({ ...selectedFilter, city: e });
  };
  const filterStores = () => {
    setShowModal(false);
    setLoading(true);
    getStores(
      selectedFilter.country,
      selectedFilter.state,
      selectedFilter.city
    );
  };
  const renderModal = () => {
    return (
      <Modal
        title="Filter by location"
        visible={showModal}
        onOk={filterStores}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <div className="mt-3">
          <Paragraph>Country</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a country"
            optionFilterProp="children"
            value={selectedFilter.country}
            onChange={onCountryChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {filter.map((i) => {
              return (
                <Option value={i.country} key={i.country}>
                  {i.country}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mt-3">
          <Paragraph>Province</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a person"
            optionFilterProp="children"
            value={selectedFilter.state}
            onChange={onStateChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentState.map((i) => {
              return (
                <Option value={i} key={i}>
                  {i}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mt-3">
          <Paragraph>City</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a city"
            optionFilterProp="children"
            value={selectedFilter.city}
            onChange={onCityChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentCity.map((i) => {
              return (
                <Option value={i.name} key={i.name}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </div>
      </Modal>
    );
  };
  if (loading) {
    return <Loading cover="content" />;
  }
  return (
    <div>
      <div className="subNavCont">
        <SubNav currentPage="stores" currentCity={selectedFilter.city} onclick={()=>setShowModal(true)} />
      </div>
      {renderModal()}
      <div style={{ padding: "0 0 0 0", marginTop: 100 }}>
        <Row
          className="row"
          style={{ paddingLeft: 30, paddingRight: 30, marginTop: 50 }}
        >
       
          <Col  stlye={{ margin: "auto" }}>
            {stores.length === 0 && (
              <h2 style={{ textAlign: "center" }}>No store found</h2>
            )}
            <div className="responsiveGrid">
              {stores.map((i) => {
                return (
                  <Link key={i.id} to={`/stores/${i.id}`}>
                    <Card
                      hoverable
                      style={{ width: 240, margin: 10, zIndex: 60 }}
                      cover={
                        <img
                          alt="example"
                          src={i.storeLogo}
                          width={300}
                          height={200}
                        />
                      }
                    >
                      <Meta
                        title={i.storeName}
                        // description="www.instagram.com"
                      />
                    </Card>
                  </Link>
                );
              })}
              
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BrowseStores;
