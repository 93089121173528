
import React from 'react';
import './panel.css';
import { Card } from 'antd';
import NewProductCard from 'components/shared-components/NewProductCard';
import { Button } from 'antd';
import { ShoppingOutlined, InfoOutlined } from "@ant-design/icons";
import '../../../components/shared-components/NewProductCard/index.scss'
import { useHistory } from 'react-router';
const { Meta } = Card;

const ResponsiveGrid = (props) => {
  let history = useHistory();
  console.log(props.onClick)
  if (props.list.length === 0) {
     return( <h2 style={{ textAlign:'center' }}>No products found!!</h2>)
  }
  else {

    return (
      //       <section class="breweries" id="breweries">
      //         <ul>
      //           {props.list.map((tile, i) => {
      // return (
      //   <li>
      //     <figure>
      //       <img
      //         src="https://firebasestorage.googleapis.com/v0/b/aspect-e0f56.appspot.com/o/Items%2F1626404969659?alt=media&token=47910d10-a71e-4e31-a324-d9e0674bdfd1"
      //         alt="Several hands holding beer glasses"
      //       />
      //       <figcaption>
      //         <h3>Billions upon billions</h3>
      //       </figcaption>
      //     </figure>
      //     <p>
      //       Made in the interiors of collapsing stars star stuff harvesting star light
      //       venture billions upon billions Drake Equation brain is the seed of
      //       intelligence?
      //     </p>
      //     <a href="#">Visit Website</a>
      //   </li>
      // );

      //            })}

      //         </ul>
      //       </section>
      // <div className="responsiveGrid">
      //   {props.list.map((tile, i) => (
      //     <div
      //       className="wrapper"
      //       key={tile.productID}
      //       onClick={() => {
      //         props.onClick(tile);
      //         console.log("asas");
      //       }}
      //     >
      //       <div
      //         className="new_card_container"
      //         // onClick={() => {
      //         //   props.onClick(tile);
      //         //   console.log("asas");
      //         // }}
      //       >
      //         <div
      //           className="top"
      //           // style={{
      //           //   background: `url(${
      //           // tile.itemImagesUrl[0]
      //           //   }) no-repeat center center `,
      //           // }}
      //         >
      //           <img
      //             src={tile.itemImagesUrl[0]}
      //             style={{ objectFit: "contain" }}
      //             width="100%"
      //             height="100%"
      //           />
      //         </div>
      //         <div className="bottom">
      //           <div className="left">
      //             <div className="details" style={{ width: "100%" }}>
      //               <h4>{tile.itemTitle}</h4>
      //               <div style={{ display: "flex" }}>
      //                 {tile.itemOriginalPrice > tile.itemDiscountedPrice ? (
      //                   <p
      //                     style={{
      //                       marginRight: 10,
      //                       fontSize: 20,
      //                       color: "green",
      //                     }}
      //                   >
      //                     CAD{tile.itemDiscountedPrice}{" "}
      //                     <strike style={{ fontSize: 20, color: "black" }}>
      //                       CAD{tile.itemOriginalPrice}
      //                     </strike>
      //                   </p>
      //                 ) : (
      //                   <p
      //                     style={{
      //                       marginRight: 10,
      //                       fontSize: 20,
      //                       color: "green",
      //                     }}
      //                   >
      //                     CAD{tile.itemOriginalPrice}{" "}
      //                   </p>
      //                 )}
      //               </div>
      //             </div>
      //           </div>
      //           <div className="right">
      //             <div className="done">
      //               <i className="material-icons">done</i>
      //             </div>
      //             <div className="details">
      //               <h1>Chair</h1>
      //               <p>Added to your cart</p>
      //             </div>
      //             <div className="remove">
      //               <i className="material-icons">clear</i>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="inside">
      //         <div className="icon">
      //           {/* <InfoOutlined style={{ fontSize: 15 }} /> */}
      //           More
      //         </div>
      //         <div className="contents">
      //           <table>
      //             <tr>
      //               <th>Category</th>
      //               <th>Condition</th>
      //             </tr>
      //             <tr>
      //               <td>{tile.itemCategory}</td>
      //               <td>{tile.itemCondition}</td>
      //             </tr>
      //             <tr>
      //               <th>Store name</th>
      //               <th>Barcode</th>
      //             </tr>
      //             <tr>
      //               <td>{tile.storeName}</td>
      //               <td>{tile.itemBarcode}</td>
      //             </tr>
      //           </table>
      //           <Button
      //             block
      //             onClick={() => {
      //               props.onClick(tile);
      //               console.log("asas");
      //             }}
      //             style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}
      //             icon={<ShoppingOutlined />}
      //           >
      //             Add to cart
      //           </Button>
      //         </div>
      //       </div>
      //     </div>
      //   ))}
      // </div>
      <div class="cardsy">
        {props.list.map((tile, i) => (
          <a href={`/products/${tile.productID}`} target="_blank">
            <div
              style={{ cursor: "pointer" }}
              className="wrapper"
              key={tile.productID}
            
            >
              <div
                className="new_card_container"
                // onClick={() => {
                //   props.onClick(tile);
                //   console.log("asas");
                // }}
              >
                <div
                  className="top"
                  // style={{
                  //   background: `url(${
                  // tile.itemImagesUrl[0]
                  //   }) no-repeat center center `,
                  // }}
                >
                  <img
                    src={tile.itemImagesUrl[0]}
                    style={{ objectFit: "contain" }}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="bottom">
                  <div className="left">
                    <div className="details" style={{ width: "100%" }}>
                      <h4>{tile.itemTitle}</h4>
                      <div style={{ display: "flex" }}>
                        {tile.itemOriginalPrice > tile.itemDiscountedPrice ? (
                          <p
                            style={{
                              marginRight: 10,
                              fontSize: 20,
                              color: "green",
                            }}
                          >
                            CAD{tile.itemDiscountedPrice}{" "}
                            <strike style={{ fontSize: 20, color: "black" }}>
                              CAD{tile.itemOriginalPrice}
                            </strike>
                          </p>
                        ) : (
                          <p
                            style={{
                              marginRight: 10,
                              fontSize: 20,
                              color: "green",
                            }}
                          >
                            CAD{tile.itemOriginalPrice}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="done">
                      <i className="material-icons">done</i>
                    </div>
                    <div className="details">
                      <h1>Chair</h1>
                      <p>Added to your cart</p>
                    </div>
                    <div className="remove">
                      <i className="material-icons">clear</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inside">
                <div className="icon">
                  {/* <InfoOutlined style={{ fontSize: 15 }} /> */}
                  More
                </div>
                <div className="contents">
                  <table>
                    <tr>
                      <th>Category</th>
                      <th>Condition</th>
                    </tr>
                    <tr>
                      <td>{tile.itemCategory}</td>
                      <td>{tile.itemCondition}</td>
                    </tr>
                    <tr>
                      <th>Store name</th>
                      <th>Barcode</th>
                    </tr>
                    <tr>
                      <td>{tile.storeName}</td>
                      <td>{tile.itemBarcode}</td>
                    </tr>
                  </table>
                  <Button
                    block
                    onClick={() => {
                      props.onClick(tile);
                    }}
                    style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}
                    icon={<ShoppingOutlined />}
                  >
                    Add to cart
                  </Button>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    ); 
  }
  
}
export default ResponsiveGrid;

