import { message } from 'antd';
import { db } from 'auth/FirebaseAuth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notification,Result, } from 'antd';

const Verify = (props) => {
     const urlParams = new URLSearchParams(window.location.search);
     const mode = urlParams.get("mode") ? urlParams.get("mode") : "";
    const code = urlParams.get("code") ? urlParams.get("code") : "";
    let history = useHistory();
    useEffect(() => {
        if (mode === "" || code === "") {
            history.push("/");
            message.error("there was an error, please verify your account again");
        }
        else {
            db.collection("customers").where("verificationID", "==", code).get().then((query) => {
                let id = "";
                query.forEach((doc) => {
                    id = doc.id;
                })
                db.collection("customers")
                  .doc(id)
                  .update({ customerAccountStatus: "verified", verificationID:"" })
                  .then(() => {
                    notification["success"]({
                      message: "Account Verified",
                      description: `Your account is verified successfuly, you can now enjoy the full features of aspect`,
                    });
                    history.push("/");

                    window.location.reload();
                  });
            });
        }


    }, []);

    return (
      <div style={{ marginTop:100 }}>
        <Result
          status="success"
          title="Account Verified Successfully"
          subTitle=""
        />
      </div>
    );
};

export default Verify;