import React, { useState } from "react";

import { MinusOutlined, PlusOutlined} from "@ant-design/icons";
import { useCart } from "react-use-cart";


import { message, Button } from "antd";
const AddToBag = (props) => {
  let { details, address, amount, onRemove, onPlus,onMinus } = props;
  details = JSON.parse(
    JSON.stringify(details).replace("itemQuantity", "stockTotal")
  );
  const [cartTotal, setCartTotal] = useState(amount);
  const { addItem, items, getItem, emptyCart, updateItem, removeItem } =
    useCart();
  const onCartChange = (action) => {
    if (cartTotal === 1 && action === "minus") {
      return;
    }
    if (action === "plus" && details.stockTotal === cartTotal) {
      return message.info("Not enough stock");
    }

    if (action === "plus" && checkIfInStock()) {
        setCartTotal((x) => x + 1);
        onPlus(details.productID);
    }
    if (action === "minus") {
        setCartTotal((x) => x - 1);
        onMinus(details.productID);
    }
    };
  const checkIfInStock = (currentAmount) => {
    let total = currentAmount + cartTotal;
    if (total > details.stockTotal) {
      return false;
    } else {
      return true;
    }
  };
    const incrementCart = () => {
      
  };
 
  
  return (
    <div className="" style={{ display: "flex" }}>
      <div
        style={{
          flex: 1,
          marginRight: 5,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
        className="borderGray300"
      >
        <div
          style={{ flex: 1, height: "100%" }}
          className="minuContainer"
          onClick={() => {
            onCartChange("minus");
          }}
        >
          <span className="minusButton">
            <MinusOutlined />
          </span>
        </div>
        <div style={{ flex: 1, fontSize: 25, color: "black" }}>{cartTotal}</div>
        <div
          style={{ flex: 1, height: "100%" }}
          className="minuContainer plusContainer"
          onClick={() => {
            onCartChange("plus");
          }}
        >
          <span className="minusButton ">
            <PlusOutlined />
          </span>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          textAlign: "center",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() => {
          onRemove(details.productID);
        }}
      >
        <Button style={{ flex: 1, textAlign: "center", color: "red" }}>
          X
        </Button>
      </div>
    </div>
  );
};

export default AddToBag;
