import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Card,
  InputNumber,
  message,
  notification,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { db, auth } from "auth/FirebaseAuth";
import MyButton from "components/shared-components/MyButton";
import { useHistory } from "react-router-dom";
import Utils from "utils";
import './index.css'
const { TextArea } = Input;

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;



const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = ({user}) => {
  const [form] = Form.useForm();
      let history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [res, setRes] = useState();
  useEffect(() => {
      if (user!==null) {
        history.push('/products')
      }
      let list = [];
        
      setRes(Utils.getAllCountries());

      
  },[])
  const onFinish = (values) => {
    let list = [];
    setButtonLoading(true);
    list.push({
      address: values.address,
      city: values.residence[2],
      country: values.residence[0],
      province: values.residence[1],
      postalCode: values.postalcode,
      main: true,
      shippingInstructions:
        values.instructions === undefined ? "" : values.instructions,
    });
    let verificationID = Utils.generateRandomString(20);
    auth.createUserWithEmailAndPassword(values.email, values.password).then((res) => {
      db.collection("customers")
        .add({
          customerFullName: values.fullName,
          customerEmail: values.email,
          customerMobile: values.phone,
          customerInternalNotes: "",

          customerAddresses: list,
          customerAccountStatus: "Pending",
          customerBag: "",
          verificationID: verificationID,
        })
        .then(() => {
          let options = {
            url: `http://localhost:3000/verify?mode=emailVerify&code=${verificationID}`,
          };
          auth.currentUser.sendEmailVerification(options).then(() => {
            // Email verification sent!
            // ...
            notification["success"]({
              message: "Account Created",
              description: `We have sent you an email to ${values.email}, please follow the instructions to verify your account`,
            });
            history.push("/");
          });
        
        });



 


    }).catch((e) => {
          setButtonLoading(false);

      console.log(e)
      if (e.code === "auth/email-already-in-use") {
        return message.error("Email already in use");
      }
    })
   
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+1">+1</Option>
        <Option value="+20">+20</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

    return (
      <div className="register-container">
        <Card
          style={{ width: "100%", margin: "auto" }}
          title="Create new account"
        >
          <Form
            // {...formItemLayout}
            layout="vertical"
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              // residence: ["Country", "Province", "City"],
              prefix: "+1",
            }}
            scrollToFirstError
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="fullName"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "The enter your full name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      message: "Password must be at least 6 characters ",
                      min: 6,
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                      min: 6,
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="residence"
                  label={
                    <span>
                      Residence&nbsp;
                      <Tooltip title="What is you current Country, Province & City?">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Please select your habitual residence!",
                    },
                  ]}
                >
                  <Cascader placeholder="Country/Province/City" options={res} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="postalcode"
                  label="postal code"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "The enter your postal code",
                    },
                  ]}
                >
                  <Input className="w-100" />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "The enter your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="instructions"
                label="Shipping Instructions"
                rules={[
                  {
                    type: "string",
                    message: "The enter your address",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Form>
          <MyButton
            type="primary"
            text="Register"
            htmlType="submit"
            loading={buttonLoading}
            onClick={() => {
              form.submit();
            }}
          />
        </Card>
      </div>
    );
};

export default Register;
