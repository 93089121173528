import React from 'react'
import { PhoneOutlined,MailOutlined } from '@ant-design/icons';
export default function Footer() {
	return (
    <footer
      className="footer"
      style={{
        height: "auto",
        margin: 0,
        paddingLeft: 30,
        backgroundColor: "#333333",
      }}
    >
      <div style={{ marginTop: 10 }}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 80, display: "grid" }}>
            <h4 style={{ color: "#cdd0d4" }}>Contact Us</h4>
            <a
              href="mailto:example@example.com"
              style={{ marginBottom: 10, color: "#8f8f92" }}
            >
              <MailOutlined /> email@email.com
            </a>
            <a
              href="tel:+2940124824932"
              style={{ marginBottom: 10, color: "#8f8f92" }}
            >
              <PhoneOutlined /> +2940124824932
            </a>
          </div>
          <div>
            <h4 style={{ color: "#cdd0d4" }}>Store</h4>
            <a style={{ color: "#8f8f92" }}>Store Account</a>
          </div>
        </div>
        <span style={{ color: "white" }}>
          Copyright &copy; {`${new Date().getFullYear()}`}{" "}
          <span style={{ color: "white" }} className="font-weight-semibold">
            Aspectsone
          </span>{" "}
          All rights reserved.
        </span>
      </div>
    </footer>
  );
}

