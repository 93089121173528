import React, { useEffect, useState } from "react";
import { Button, Card, message, Typography, Empty } from "antd";
import "./order.css";
import { db } from "auth/FirebaseAuth";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
const { Text } = Typography;

const Orders = (props) => {
  let history = useHistory();
  let { userData, user } = props;
  let [order, setOrder] = useState([]);
  let [loading, setloading] = useState(true);

  let [firestoreIndex, setFireStoreIndex] = useState("");
  useEffect(() => {
    if (user===null) {
      history.push('/')
      return
    }
    db.collection("orders")
      .where("customerID", "==", userData.id)
      .limit(15)
      // .orderBy("orderCreatedAt", "desc")

      .get()
      .then((query) => {
        let list = [];
        query.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        setTimeout(() => {
          setFireStoreIndex(query);
          setOrder(list);
          setloading(false);
        }, 1000);
      })
      .catch((e) => console.log(e));

    // let listen = db
    //   .collection("orders")
    //   .where("customerID", "==", userData.id)
    //   .onSnapshot((snap) => {

    //     snap.forEach((doc) => {
    //       list.push({ ...doc.data(), id: doc.id });
    //     });
    //     console.log(list);
    //     setOrder(list);
    //   });

    // return () => { listen();}
  }, []);
  const loadMore = () => {
    db.collection("orders")
      //  .orderBy("id")
      .startAfter(firestoreIndex.docs[firestoreIndex.docs.length - 1])
      .limit(15)
      .get()
      .then((query) => {
        if (query.empty) {
          return message.info("No more orders");
        }

        let list = order;
        query.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        setFireStoreIndex(query);
        setOrder(list);
      })
      .catch((e) => {
        return message.info("No more orders");
      });
  };
  if (loading) {
    return (
    
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
        <Loading size="large" />
</div>
    );
  }
  return (
    <div style={{ marginTop: 100 }}>
      {order.length !== 0 && (
        <div style={{ width: "95%", margin: "auto" }}>
          <h1>Orders</h1>
          {order.length === 0 && <h1 className="text-center">No orders yet</h1>}
          {order.map((i) => {
            return (
              <Card key={i.id} style={{ border: 0 }}>
                <div className="orderContainer">
                  <div className="imgContainer" style={{ minWidth: 180 }}>
                    <img className="imag" src={i.itemImage} />
                  </div>
                  <div className="detailsContainer ml-3">
                    <Text>
                      {moment(i.orderCreatedAt.toDate()).format("MM/DD/YYYY")}
                    </Text>
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Order: #{i.id}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {i.itemTitle}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        // color: "black",
                        fontWeight: "400",
                      }}
                    >
                      Item ID: {i.itemID}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        // color: "black",
                        fontWeight: "400",
                      }}
                    >
                      Barcode: {i.itemBarcode}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        // color: "black",
                        fontWeight: "400",
                      }}
                    >
                      Condition: {i.itemCondition}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        // color: "black",
                        fontWeight: "400",
                      }}
                    >
                      Quantity: {i.orderQuantity}
                    </Text>
                  </div>
                  <div className="statusContainer mr-3">
                    <div className="mb-4">
                      <Text
                        style={{
                          fontSize: 16,
                          color: "black",
                          fontWeight: "bold",
                          marginBottom: 10,
                        }}
                      >
                        Status: {i.orderStatus}
                      </Text>
                    </div>

                    <div style={{ display: "grid" }}>
                      {" "}
                      <Text
                        style={{
                          fontSize: 16,
                          // color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Store: {i.storeName}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                          // color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {i.orderPickupAddress
                          ? `Pick up: ${i.orderPickupAddress.address}`
                          : `Delivery: ${i.orderDestinationAddress.address}`}
                      </Text>
                      <Text
                        style={{
                          fontSize: 16,
                          // color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {i.orderPickupAddress
                          ? `${i.orderPickupAddress.city}-${i.orderPickupAddress.province}-${i.orderPickupAddress.country}`
                          : `${i.orderDestinationAddress.city}-${i.orderDestinationAddress.province}-${i.orderDestinationAddress.country}`}
                      </Text>
                    </div>
                  </div>
                  <div className="salesContainer">
                    <Card style={{ height: "100%" }} title="Sales Proceeds">
                      <div style={{ display: "grid" }}>
                        <div className="ml-2" style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontSize: 16,
                              // color: "black",
                              fontWeight: "600",
                              flex: 1,
                            }}
                          >
                            Subtotal:
                          </Text>
                          <Text
                            style={{
                              fontSize: 16,
                              // color: "black",
                              fontWeight: "600",
                              flex: 1,
                            }}
                          >
                            CAD${i.orderSubTotalPrice}
                          </Text>
                        </div>
                        <div className="ml-2 mt-4" style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontSize: 16,
                              // color: "black",
                              fontWeight: "600",
                              flex: 1,
                            }}
                          >
                            Tax:
                          </Text>
                          <Text
                            style={{
                              fontSize: 16,
                              // color: "black",
                              fontWeight: "600",
                              flex: 1,
                            }}
                          >
                            CAD${i.orderTax}
                          </Text>
                        </div>
                        <div className="ml-2 mt-4" style={{ display: "flex" }}>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "black",
                              fontWeight: "bold",
                              flex: 1,
                            }}
                          >
                            Total:
                          </Text>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "black",
                              fontWeight: "bold",
                              flex: 1,
                            }}
                          >
                            $CAD
                            {parseFloat(
                              i.orderSubTotalPrice + i.orderTax
                            ).toFixed(1)}
                          </Text>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Card>
            );
          })}
          {order.length !== 0 && (
            <div className="text-center">
              <Button onClick={loadMore}>Load More</Button>
            </div>
          )}
        </div>
      )}
      {order.length === 0 && (
        <Empty
          image="https://firebasestorage.googleapis.com/v0/b/aspect-e0f56.appspot.com/o/assets%2F172-1723816_trolley-clipart-shopping-cart-shopping-cart-icon-png-removebg.png?alt=media&token=034c436c-20ee-4300-acc7-dee8744c8d4b"
          imageStyle={{
            height: 150,
          }}
          description={
            <span style={{ fontWeight: "bold", fontSize: 18 }}>
              You have no orders
            </span>
          }
        >
          <Button
            type="primary"
            onClick={() => {
              history.push("/");
            }}
            size="large"
          >
            Order now
          </Button>
        </Empty>
      )}
    </div>
  );
};

export default Orders;
