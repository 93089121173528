import React, { lazy, Suspense,} from "react";
import { Switch, Route,  } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import BrowseStores from "./stores/browseStores";
import BrowseProducts from './products/BrowseProducts';
import FinishSignUp from './finishSignUp';
import Profile from './profile';
import Cart from './cart/index';
import StoreDetails from './stores/storeDetails';
import Orders from './orders';
import Verify from "./verify";
import ProductDetails from "./products/productDetails";
import Register from '../auth-views/register/index';
import Home from './home/index'
export const AppViews = (props) => {
  let { user, userData } = props;
 
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path="/"
          exact
          component={() => <Home user={user} userData={userData} />}
        />
        <Route
          path="/register"
          exact
          component={() => <Register user={user} userData={userData} />}
        />
        <Route
          path="/products"
          exact
          component={() => <BrowseProducts user={user} userData={userData} />}
        />
        <Route
          path="/products"
          exact
          component={() => <BrowseProducts user={user} userData={userData} />}
        />
        <Route
          path="/finishSignUp"
          exact
          component={() => <FinishSignUp userData={userData} />}
        />

        <Route
          path="/stores"
          exact
          component={() => <BrowseStores user={user} userData={userData} />}
        />
        <Route
          path="/stores/:id"
          exact
          component={() => <StoreDetails user={user} userData={userData} />}
        />
        <Route
          path="/products/:id"
          component={() => <ProductDetails user={user} userData={userData} />}
        />
        <Route
          path="/profile"
          component={() => <Profile user={user} userData={userData} />}
        />
        <Route
          path="/bag"
          component={() => <Cart user={user} userData={userData} />}
        />
        <Route
          path="/orders"
          component={() => <Orders user={user} userData={userData} />}
        />
        <Route
          path="/verify"
          component={() => <Verify user={user} userData={userData} />}
        />
      </Switch>
    </Suspense>
  );
}

export default React.memo(AppViews);