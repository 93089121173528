import React, { useState, useEffect, useRef,  } from "react";
import { connect } from "react-redux";
import {
  SearchOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  HomeFilled,
  ShoppingOutlined,
  ShoppingFilled,
  ShopOutlined,
  ShopFilled,
  DownOutlined,
} from "@ant-design/icons";
import SignIn from '../shared-components/Signin/index';
import '../../assets/searchInput.css';
import { Input, AutoComplete, Menu, Dropdown } from "antd";
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import utils from 'utils'
import { useHistory, useLocation } from "react-router-dom";
import FuzzySearch from "fuzzy-search";
import {db,auth} from '../../auth/FirebaseAuth'


export const HeaderNav = props => {
  let history = useHistory();
  let loc = useLocation();
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction, user, userData } = props;
  const [searchActive, setSearchActive] = useState(false);
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const searchProducts = new FuzzySearch(products, ["itemTitle"]);
  const inputRef = useRef(null);
  
const dropdownMenu = (row) => (
  <div className="nav-profile nav-dropdown">
    <div className="nav-profile-header">
      <div className="d-flex">
        <div className="pl-3">
          <h4 className="mb-0">Welcome to Aspect</h4>
          <span className="text-muted">{userData.fullName}</span>
        </div>
      </div>
    </div>
    <div className="nav-profile-body">
      <Menu>
        <Menu.Item
          key={1}
          onClick={(e) => {
            
              history.push("/profile");
          }}
        >
          <span>
            <UserOutlined />
            <span className="font-weight-normal">Profile</span>
          </span>
        </Menu.Item>
        <Menu.Item
          key={2}
          onClick={(e) => {
            auth.signOut().then(() => {
              window.location.href='/'
              window.location.reload();
            });
          }}
        >
          <span>
            <LogoutOutlined className="mr-3" />
            <span className="font-weight-normal">Sign Out</span>
          </span>
        </Menu.Item>
      </Menu>
    </div>
  </div>
);

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const mode = ()=> {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }
  

  useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");

    hamburger.addEventListener("click", mobileMenu);

    function mobileMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    }
    if (!isMobile) {
      onSearchClose()
    }
    let productsListener = db.collection('items').onSnapshot((snap) => {
      let list = [];
      snap.forEach((doc) => {
        list.push({ ...doc.data(), id: doc.id });
      });
      setProducts(list);
    })
    return () => { productsListener(); }
  }, []);
  

  const onSearch = (e) => {
    const result = searchProducts.search(e);
     let op = [];
    if (e==="") {
        setValue(e);
        setOptions(op);
    } else {

        result.map((i) => {
          op.push({
            value: i.id,
            label: (
                <div className="search-list-item" onClick={() => {
                  history.push(`/products/${i.id}`)
                }}>
                  <div className="icon"><img src={i.itemImagesUrl[0]} width="50" height="50" /></div>
                  <div>
                    <div className="font-weight-semibold">{i.itemTitle}</div>
                    {/* <div className="font-size-sm text-muted">'vvv' </div> */}
                  </div>
                </div>
            ),
          });
        });
        setValue(e);
        setOptions(op);
    }
  
  };
  const onSelect = (e) => {
    setValue("");
    // history.push(`/products/${e}`)
  };
  if (user===null) {
    return (
      <header
        className="header"
        style={{ position: "fixed", zIndex: "6000", width: "100%" }}
      >
        <SignIn
          show={showSignIn}
          onOk={() => {
            setShowSignIn(false);
          }}
          onCancel={() => {
            setShowSignIn(false);
          }}
        />
        <nav className="navbar">
          <div href="#" className="nav-logo">
            Aspect Store
          </div>
          <ul className="nav-menu">
            <li className="ant-menu-item ant-menu-item-only-child ">
              {/* <div class="search-box">
               <button class="btn-search">
                 <SearchOutlined />
               </button>
               <input
                  type="text"
                  class="input-search"
                  placeholder="Search..."
                />
              </div> */}
              {/* <Search
              placeholder="Search Products"
              size="small"
              onSearch={onSearch}
              style={{ width: 300 }}
            /> */}
              <AutoComplete
                ref={inputRef}
                className={`nav-search-input ${isMobile ? "is-mobile" : ""} ${
                  mode === "light" ? "light" : ""
                }`}
                dropdownClassName="nav-search-dropdown"
                options={options}
                onSelect={onSelect}
                onChange={onSearch}
                open={true}
                value={value}
              >
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined className="mr-0" />}
                />
              </AutoComplete>
            </li>
            <li
              onClick={() => {
                 var element = document.getElementById("me");
                history.push("/");
              }}
              className="ant-menu-item ant-menu-item-only-child"
              style={{ fontWeight: "600", fontSize: 18 }}
            >
              Home
            </li>
            <li
              onClick={() => {
              
                setShowSignIn(true);
              }}
              className="ant-menu-item ant-menu-item-only-child"
              style={{ fontWeight: "600", fontSize: 18 }}
            >
              Sign in
            </li>
            <li
              onClick={() => {
                history.push("/register");
              }}
              className="ant-menu-item ant-menu-item-only-child"
              style={{ fontWeight: "600", fontSize: 18 }}
            >
              Register
            </li>
          </ul>
          <div className="hamburger" id="ham">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
    );
  }
  else {
    return (
      <header
        className="header"
        style={{ position: "fixed", zIndex: "600", width: "100%" }}
      >
        <SignIn
          show={showSignIn}
          onOk={() => {
            setShowSignIn(false);
          }}
          onCancel={() => {
            setShowSignIn(false);
          }}
        />
        <nav className="navbar">
          <div href="#" className="nav-logo">
            Aspect Store
          </div>
          <ul className="nav-menu">
            <li className="ant-menu-item ant-menu-item-only-child ">
              <AutoComplete
                ref={inputRef}
                // dropdownStyle={{ zIndex:2000000000000000 }}
                className={`nav-search-input ${isMobile ? "is-mobile" : ""} ${
                  mode === "light" ? "light" : ""
                }`}
                dropdownClassName="nav-search-dropdown"
                options={options}
                onSelect={onSelect}
                onChange={onSearch}
                open={true}
                value={value}
              >
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined className="mr-0" />}
                />
              </AutoComplete>
            </li>
            <li
              onClick={() => {
                history.push("/products");
              }}
              className="ant-menu-item ant-menu-item-only-child ll"
              style={{
                fontWeight: "600",
                fontSize: 18,
                
                color:
                  loc.pathname === "/" || loc.pathname === "/products"
                    ? "#0088CC"
                    : "#475569",
              }}
            >
              {loc.pathname === "/" ? (
                <HomeFilled className="mr-0" />
              ) : (
                <HomeOutlined className="mr-0" />
              )}
              Home
            </li>
            <li
              onClick={() => {
                history.push("/orders");
              }}
              className="ant-menu-item ant-menu-item-only-child ll"
              style={{
                fontWeight: "600",
                fontSize: 18,
                
                color: loc.pathname === "/orders" ? "#0088CC" : "#475569",
              }}
            >
              {loc.pathname === "/orders" ? (
                <ShopFilled className="mr-0" />
              ) : (
                <ShopOutlined className="mr-0" />
              )}
              Orders
            </li>
            <li
              onClick={() => {
                //  let element = document.getElementById("me");
                //  ReactDOM.findDOMNode(element).classList.remove("active");
                history.push("/bag");
              }}
              className="ant-menu-item ant-menu-item-only-child ll"
              style={{
                fontWeight: "600",
                fontSize: 18,
                
                color: loc.pathname === "/bag" ? "#0088CC" : "#475569",
              }}
            >
              {loc.pathname === "/bag" ? (
                <ShoppingFilled className="mr-0" />
              ) : (
                <ShoppingOutlined className="mr-0" />
              )}
              Bag
            </li>

            <Dropdown
              placement="bottomRight"
              overlay={dropdownMenu}
              trigger={["click"]}
            >
              <li
                onTouchMove={() => {}}
                className="ant-menu-item ant-menu-item-only-child ll"
                style={{
                  fontWeight: "600",
                  fontSize: 18,
                  
                  color: loc.pathname === "/profile" ? "#0088CC" : "#475569",
                }}
              >
                <UserOutlined className="mr-0" />
                Account
              </li>
            </Dropdown>
          </ul>
          <div className="hamburger" id="me">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
    );
  }
  
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } =  theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);