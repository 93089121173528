import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Select,
  Card,
  Modal,
  Checkbox,
  Collapse,
  List
} from "antd";
import "./layout.css";
import './gridRes.css'
import SubNav from "components/shared-components/SubNav";
import ResponsiveGrid from "../home/Panel";
import { EditOutlined } from "@ant-design/icons";
import "react-gallery-carousel/dist/index.css";
import Loading from "components/shared-components/Loading";
import MyModal from "components/shared-components/MyModal";
import { db, auth } from "../../../auth/FirebaseAuth";
import Utils from "utils";
const { Title, Paragraph, } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const BrowseProducts = (props) => {
  let { user, userData } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get("country")?urlParams.get("country"):'';
  const state = urlParams.get("province")?urlParams.get("province"):'';
  const city = urlParams.get("city")?urlParams.get("city"):'';
  const [filter, setFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    country: country,
    state: state,
    city: city,
  });
  const [currentState, setCurrentState] = useState([]);
  const [currentCity, setCurrentCity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [finalFilter, setFinalFilter] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCats, setSelectedCats] = useState("");
  const getProducts = async () => {
    let storesList = [];
    let productsList = [];
    let filteredList = [];
    let storesQuery = await db.collection("stores").get();
    storesQuery.forEach((doc) => {
      storesList.push({ ...doc.data(), id: doc.id });
    });
    let productsQuery = await db.collection("items").get();
    productsQuery.forEach((doc) => {
      let storeData = Utils.filterArray(
        storesList,
        "id",
        doc.data().storeID
      )[0];
      if (doc.data().itemNetworks) {
         productsList.push({
           ...doc.data(),
           productID: doc.id,
           storeName:storeData? storeData.storeName:"",
           // storeCountry: storeData.country,
           // storeState: storeData.province,
           // storeCity: storeData.city,
         });
      }
     
    });
    if (user === null) {
      // if (country !== "" && country !== null) {
      //   filteredList = Utils.filterArray(productsList, "storeCountry", country);
      // }
      // if (state !== "" && state !== null) {
      //   filteredList = Utils.filterArray(productsList, "storeState", state);
      // }
      // if (city !== "" && city !== null) {
      //   filteredList = Utils.filterArray(productsList, "storeCity", city);
      // }
      // if (!country && !state && !city) {
      //   filteredList = productsList;
      // }
      productsList.map((i) => {
        if (Utils.filterProductByNetworks(country, state, city, i)) {
          filteredList.push(i);
        } 
        

      })
    } else {
      let mainUserAddress = Utils.filterArray(
        userData.customerAddresses,
        "main",
        true
      );

      productsList.map((i) => {
        
        if (Utils.filterProductByNetworks(mainUserAddress[0].country, mainUserAddress[0].province, mainUserAddress[0].city, i)) {
          filteredList.push(i);
        } 
      
      });
      setSelectedFilter({
        country: mainUserAddress[0].country,
        state: mainUserAddress[0].province,
        city: mainUserAddress[0].city,
      });
    }

    setFinalFilter(filteredList);
    setProducts(productsList);
    setFilteredProducts(filteredList);
    setLoading(false);
  };

  useEffect(() => {
      let set = {
        url: "http://localhost:3000/verify",
      };

    async function getNetworks() {
      let networksQuery = await db.collection("networks").get();
      let list = [];
      networksQuery.forEach((doc) => {
        list.push({ ...doc.data() });
      });
      setFilter(list);
    }
    db.collection("settings")
      .doc("categories")
      .get()
      .then((doc) => {
        let cats = [];
        doc.data().options.map((i) => {
          cats.push({ label: i.label, value: i.value });
        });
        setCategories(cats);
      });
    getProducts();
    // getStores(country, state, city);
    getNetworks();
  }, []);

  const onCountryChange = (e) => {
    let list = Utils.filterArray(filter, "country", e);
    let states = [];
    list[0].stateCity.map((i) => {
      states.push(i.state);
    });
    setCurrentState(states);
    setCurrentCity([]);
    setSelectedFilter({ country: e, city: "", state: "" });
  };
  const onStateChange = (e) => {
    let mainList = Utils.filterArray(filter, "country", selectedFilter.country);
    let list = Utils.filterArray(mainList[0].stateCity, "state", e);

    setCurrentCity(list[0].cities);
    setSelectedFilter({ ...selectedFilter, state: e, city: "" });
  };

  const onCityChange = (e) => {
    setSelectedFilter({ ...selectedFilter, city: e });
  };

  const onCatChange = (selected) => {
       setSelectedCats(selected);

    filterCats(selected);
  }
  
  const onAdressSelectChange = (address) => {
    let filteredList = [];
     products.map((i) => {
       if (
         Utils.filterProductByNetworks(
           address.country,
           address.province,
           address.city,
           i
         )
       ) {
         filteredList.push(i);
       }
     });
      setSelectedFilter({
        country: address.country,
        state: address.province,
        city: address.city,
      });

    setFinalFilter(filteredList);
    setFilteredProducts(filteredList);
    setShowModal(false)
  }

  const filterProducts = () => {
    setShowModal(false);
    setLoading(true);

      let l = [];

    products.map((i) => {
      if (
       Utils.filterProductByNetworks(
          selectedFilter.country,
          selectedFilter.state,
          selectedFilter.city,
          i
        )
      ) {
        l.push(i);
      }
    });
    setFinalFilter(l);
    setLoading(false)
  };
  const renderModal = () => {
    return (
      <Modal
        title="Filter by location"
        visible={showModal}
        onOk={filterProducts}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <div className="mt-3">
          <Paragraph>Country</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a country"
            optionFilterProp="children"
            value={selectedFilter.country}
            onChange={onCountryChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {filter.map((i) => {
              return (
                <Option value={i.country} key={i.country}>
                  {i.country}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mt-3">
          <Paragraph>Province</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a person"
            optionFilterProp="children"
            value={selectedFilter.state}
            onChange={onStateChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentState.map((i) => {
              return (
                <Option value={i} key={i}>
                  {i}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mt-3">
          <Paragraph>City</Paragraph>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a city"
            optionFilterProp="children"
            value={selectedFilter.city}
            onChange={onCityChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentCity.map((i) => {
              return (
                <Option value={i.name} key={i.name}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </div>
        {user !== null && (
          <div className="mt-4">
            <List
              size="small"
              header={<div>Deliver to a saved addresses</div>}
              bordered
              dataSource={userData.customerAddresses}
              renderItem={(item) => (
                <List.Item
                  className="list"
                  onClick={() => {
                    onAdressSelectChange(item);
                  }}
                >
                  {item.address}
                  <br />
                  {item.country} - {item.province} - {item.city}
                </List.Item>
              )}
            />
          </div>
        )}
      </Modal>
    );
  };
  const filterCats = (e) => {
    let list = [];
    if (e.length === 0) {
      setFinalFilter(filteredProducts);
      return;
    } else {
      setFinalFilter(Utils.filterArray(filteredProducts, "itemCategory", e));
    }
  };
  const onChangeCheckBox = (e) => {
    setSelectedCats(e);
    filterCats(e);
  };
  const productCardClick = (i) => {
    setSelectedProduct(i);
    setShowProductModal(true);
  };
  if (loading) {
    return <Loading cover="content" />;
  }
  return (
    <div>
      <div className="subNavCont">
        <SubNav
          cats={categories}
          onCats={filterCats}
          currentCat={selectedCats}
          currentPage="products"
          currentCity={selectedFilter.city}
          selectedCat={selectedCats}
          onCats={onCatChange}
          onclick={() => {
            setShowModal(true);
          }}
        />
      </div>
      {renderModal()}
      <div style={{ padding: "0 0 0 0", marginTop: 50 }}>
        
        <Row className="row padder" style={{ width: "100%", margin: "auto" }}>
          {/* <MyModal
            show={showProductModal}
            showCart={
              user && userData.customerAccountStatus === "verified"
                ? true
                : false
            }
            handleClose={() => {
              setShowProductModal(false);
            }}
            address={userData ? userData.customerAddresses[0] : ""}
            details={selectedProduct}
          /> */}
          {/* <Col
            flex="1 0 100%"
            style={{ margin: "auto", zIndex: "50" }}
            className="column Red"
          > */}
            <ResponsiveGrid
              list={finalFilter}
              onClick={productCardClick}
              cats={categories}
              onCats={filterCats}
              currentCat={selectedCats}
            />
          {/* </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default BrowseProducts;
